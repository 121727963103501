<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";

export type FormFooterVariant = "spaced" | "sticky";

type Props = {
    variant?: FormFooterVariant;
};

const { variant = undefined } = defineProps<Props>();
const classes: AntlerClasses<Props> = {
    base: "flex",
    variants: {
        variant: {
            spaced: "justify-between",
            sticky: "sticky bottom-0 left-0 w-full z-10 bg-white py-3 border-t",
        },
    },
};
const { aClass } = installAntlerComponent("form-footer", { variant }, classes);
</script>

<template>
    <div :class="aClass()">
        <slot />
    </div>
</template>
